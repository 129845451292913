import Skeleton from "react-loading-skeleton";

import { FieldControlContainer, FieldControlSkeleton } from "./styles";
import { CONTROLS } from "./constants";

const FieldControl = (props) => {
  const {
    id,
    type,
    variant,
    placeholder,
    isReadOnly,
    isDisabled,
    showSkeleton,
    value,
    error,
    onChange,
    validateBeforeChange,
    ...rest
  } = props;

  const Control = CONTROLS[type] || CONTROLS.text;

  const setValue = (newValue) => {
    if (typeof newValue === "function") {
      onChange(newValue(value));
    } else {
      onChange(newValue);
    }
  };

  return (
    <FieldControlContainer>
      {showSkeleton ? (
        <FieldControlSkeleton type={type}>
          <Skeleton />
        </FieldControlSkeleton>
      ) : (
        <Control
          id={id}
          name={id}
          type={type}
          variant={variant}
          placeholder={placeholder}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          showSkeleton={showSkeleton}
          value={value}
          error={error}
          onChange={onChange}
          validateBeforeChange={validateBeforeChange}
          setValue={setValue}
          {...rest}
        />
      )}
    </FieldControlContainer>
  );
};

export default FieldControl;
