import { generateID } from "react-querybuilder";
import {
  rangeOperators,
  multiValueOperators,
  nullValueOperators,
  allOperators,
} from "./constants";

export const isNullOperator = (operator) =>
  nullValueOperators.map((o) => o.value).includes(operator);

export const isRangeOperator = (operator) =>
  rangeOperators.map((o) => o.value).includes(operator);

export const isListOperator = (operator) =>
  multiValueOperators.map((o) => o.value).includes(operator);

export const buildQueryRuleFromApiFilter = (apiFilter = {}) => {
  const isGroup = apiFilter.hasOwnProperty("filters");

  if (isGroup) {
    return {
      id: apiFilter.id || generateID(),
      combinator: apiFilter.operator,
      rules: apiFilter.filters.map(buildQueryRuleFromApiFilter),
    };
  }

  const isRangeFilter = isRangeOperator(apiFilter.operator);

  return {
    id: apiFilter.id || generateID(),
    field: apiFilter.field,
    operator: apiFilter.operator,
    value: isRangeFilter ? [apiFilter.from, apiFilter.to] : apiFilter.value,
  };
};

const staticId = generateID();
export const convertFiltersToQuery = (filters = [], isLoading = false) => {
  if (isLoading) {
    return {
      id: staticId,
      combinator: "and",
      rules: [
        {
          id: generateID(),
          combinator: "and",
          isLoading: true,
          rules: [
            {
              id: generateID(),
              operator: "=",
              isLoading: true,
            },
            {
              id: generateID(),
              operator: "=",
              isLoading: true,
            },
          ],
        },
      ],
    };
  } else {
    return filters.reduce(
      (query, currentFilter) => {
        const convertedFilter = buildQueryRuleFromApiFilter(currentFilter);
        query.rules = query.rules.concat(convertedFilter);

        return query;
      },
      {
        id: staticId,
        combinator: "and",
        rules: [],
      }
    );
  }
};

export const buildApiFilterFromQueryRule = (queryRule = {}) => {
  const isGroup = queryRule.hasOwnProperty("combinator");

  if (isGroup) {
    return {
      id: queryRule.id,
      operator: queryRule.combinator,
      filters: queryRule.rules.map(buildApiFilterFromQueryRule),
    };
  }

  const isRangeFilter = isRangeOperator(queryRule.operator);
  const fieldValueObject = isRangeFilter
    ? { from: queryRule.value[0], to: queryRule.value[1] }
    : { value: queryRule.value };

  return {
    id: queryRule.id,
    field: queryRule.field,
    operator: queryRule.operator,
    ...fieldValueObject,
  };
};

export const convertQueryToFilters = (query = {}) => {
  return query.rules.map(buildApiFilterFromQueryRule);
};

export const retrofitSelectChangeHandler =
  (originalHandler, isMultiSelect) => (valueContainer) => {
    if (isMultiSelect) {
      return originalHandler(valueContainer.map((item) => item.value));
    } else {
      return originalHandler(valueContainer.value);
    }
  };

export const retrofitMultiNumberChangeHandler =
  (originalHandler) => (event) => {
    const formattedValue = event.target.value
      .replaceAll(/[^0-9,. ]+/g, "")
      .split(/\s*,\s*/)
      .map((v) => Number(v));

    originalHandler(formattedValue);
  };

export const retrofitTextChangeHandler = (originalHandler) => (event) =>
  originalHandler(event.target.value);

export const getSelectValueFromOptions = (options, value, isMultiSelect) => {
  if (isMultiSelect) {
    return (
      options.filter((option) => value?.includes(option.value)) || options[0]
    );
  } else {
    return options.find((option) => option.value === value) || options[0];
  }
};

export const getMockFieldsFromApi = () => {
  return [
    {
      name: "name",
      value: "name",
      label: "Name",
      inputType: "text",
      operators: allOperators,
    },
    {
      name: "age",
      value: "age",
      label: "Age",
      inputType: "number",
      operators: allOperators,
    },
    {
      name: "gender",
      value: "gender",
      label: "Gender",
      inputType: "text",
      operators: allOperators,
    },
    {
      name: "state",
      value: "state",
      label: "State",
      inputType: "select",
      valueEditorType: "multiselect",
      operators: allOperators,
      values: [
        {
          name: "AL",
          value: "AL",
          label: "AL",
        },
        {
          name: "AK",
          value: "AK",
          label: "AK",
        },
        {
          name: "AS",
          value: "AS",
          label: "AS",
        },
        {
          name: "AZ",
          value: "AZ",
          label: "AZ",
        },
        {
          name: "AR",
          value: "AR",
          label: "AR",
        },
        {
          name: "CA",
          value: "CA",
          label: "CA",
        },
        {
          name: "CO",
          value: "CO",
          label: "CO",
        },
        {
          name: "CT",
          value: "CT",
          label: "CT",
        },
        {
          name: "DE",
          value: "DE",
          label: "DE",
        },
        {
          name: "DC",
          value: "DC",
          label: "DC",
        },
        {
          name: "FM",
          value: "FM",
          label: "FM",
        },
        {
          name: "FL",
          value: "FL",
          label: "FL",
        },
        {
          name: "GA",
          value: "GA",
          label: "GA",
        },
        {
          name: "GU",
          value: "GU",
          label: "GU",
        },
        {
          name: "HI",
          value: "HI",
          label: "HI",
        },
        {
          name: "ID",
          value: "ID",
          label: "ID",
        },
        {
          name: "IL",
          value: "IL",
          label: "IL",
        },
        {
          name: "IN",
          value: "IN",
          label: "IN",
        },
        {
          name: "IA",
          value: "IA",
          label: "IA",
        },
        {
          name: "KS",
          value: "KS",
          label: "KS",
        },
        {
          name: "KY",
          value: "KY",
          label: "KY",
        },
        {
          name: "LA",
          value: "LA",
          label: "LA",
        },
        {
          name: "ME",
          value: "ME",
          label: "ME",
        },
        {
          name: "MH",
          value: "MH",
          label: "MH",
        },
        {
          name: "MD",
          value: "MD",
          label: "MD",
        },
        {
          name: "MA",
          value: "MA",
          label: "MA",
        },
        {
          name: "MI",
          value: "MI",
          label: "MI",
        },
        {
          name: "MN",
          value: "MN",
          label: "MN",
        },
        {
          name: "MS",
          value: "MS",
          label: "MS",
        },
        {
          name: "MO",
          value: "MO",
          label: "MO",
        },
        {
          name: "MT",
          value: "MT",
          label: "MT",
        },
        {
          name: "NE",
          value: "NE",
          label: "NE",
        },
        {
          name: "NV",
          value: "NV",
          label: "NV",
        },
        {
          name: "NH",
          value: "NH",
          label: "NH",
        },
        {
          name: "NJ",
          value: "NJ",
          label: "NJ",
        },
        {
          name: "NM",
          value: "NM",
          label: "NM",
        },
        {
          name: "NY",
          value: "NY",
          label: "NY",
        },
        {
          name: "NC",
          value: "NC",
          label: "NC",
        },
        {
          name: "ND",
          value: "ND",
          label: "ND",
        },
        {
          name: "MP",
          value: "MP",
          label: "MP",
        },
        {
          name: "OH",
          value: "OH",
          label: "OH",
        },
        {
          name: "OK",
          value: "OK",
          label: "OK",
        },
        {
          name: "OR",
          value: "OR",
          label: "OR",
        },
        {
          name: "PW",
          value: "PW",
          label: "PW",
        },
        {
          name: "PA",
          value: "PA",
          label: "PA",
        },
        {
          name: "PR",
          value: "PR",
          label: "PR",
        },
        {
          name: "RI",
          value: "RI",
          label: "RI",
        },
        {
          name: "SC",
          value: "SC",
          label: "SC",
        },
        {
          name: "SD",
          value: "SD",
          label: "SD",
        },
        {
          name: "TN",
          value: "TN",
          label: "TN",
        },
        {
          name: "TX",
          value: "TX",
          label: "TX",
        },
        {
          name: "UT",
          value: "UT",
          label: "UT",
        },
        {
          name: "VT",
          value: "VT",
          label: "VT",
        },
        {
          name: "VI",
          value: "VI",
          label: "VI",
        },
        {
          name: "VA",
          value: "VA",
          label: "VA",
        },
        {
          name: "WA",
          value: "WA",
          label: "WA",
        },
        {
          name: "WV",
          value: "WV",
          label: "WV",
        },
        {
          name: "WI",
          value: "WI",
          label: "WI",
        },
        {
          name: "WY",
          value: "WY",
          label: "WY",
        },
      ],
    },
    {
      name: "date",
      value: "date",
      label: "Date",
      inputType: "date",
      valueEditorType: "date",
      dateType: "date",
      operators: allOperators,
    },
    {
      name: "month",
      value: "month",
      label: "Month",
      inputType: "date",
      valueEditorType: "date",
      dateType: "month",
      operators: allOperators,
    },
    {
      name: "year",
      value: "year",
      label: "Year",
      inputType: "date",
      valueEditorType: "date",
      dateType: "year",
      operators: allOperators,
    },
    {
      name: "quarter",
      value: "quarter",
      label: "Quarter",
      inputType: "date",
      valueEditorType: "date",
      dateType: "quarter",
      operators: allOperators,
    },
    {
      name: "decile",
      value: "decile",
      label: "Decile",
      inputType: "number",
      operators: allOperators,
    },
    {
      name: "decile",
      value: "decile",
      label: "Decile",
      inputType: "number",
      operators: allOperators,
    },
    {
      name: "zip",
      value: "zip",
      label: "Zipcodes",
      inputType: "zip",
      operators: allOperators,
    },
  ];
};

export const mockFiltersFromApi = () => {
  return [
    {
      operator: "or",
      filters: [
        {
          type: "model",
          model_id: "bariatric_surgery",
          field: "decile",
          operator: "between",
          from: 7,
          to: 10,
        },
        {
          field: "date",
          operator: "=",
          value: "2023-08-23",
        },
        {
          field: "name",
          operator: "=",
          value: "Kevin",
        },
        {
          type: "model",
          model_id: "thoracic_surgery",
          field: "decile",
          operator: "=",
          value: 10,
        },
        {
          type: "model",
          model_id: "thoracic_surgery",
          field: "decile",
          operator: "between",
          from: 7,
          to: 10,
        },
      ],
    },
    {
      operator: "or",
      filters: [
        { operator: "=", value: "female", field: "gender" },
        {
          operator: "or",
          filters: [
            { operator: "=", field: "gender", value: "male" },
            {
              operator: "between",
              field: "age",
              from: 45,
              to: 65,
            },
          ],
        },
      ],
    },
  ];
};
