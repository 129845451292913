import { useCallback, useContext, useMemo } from "react";
import ToastContext from "../context/ToastContext";

let toastId = 0;

const useToaster = () => {
  const { toasts, setToasts } = useContext(ToastContext);

  const dismissById = useCallback(
    (id) => {
      setToasts((prevToastMap) => {
        const { [id]: dismissedToast, ...remainingToasts } = prevToastMap;

        return remainingToasts;
      });
    },
    [setToasts]
  );

  // Todo: limit to 3 toasts
  const createToast = useCallback(
    ({ type, title, message, isInfinite = false }) => {
      const id = toastId;

      setToasts((prevToastMap) => {
        return {
          ...prevToastMap,
          [id]: {
            id,
            type,
            title,
            message,
            isInfinite,
          },
        };
      });

      if (!isInfinite) {
        setTimeout(() => {
          dismissById(id);
        }, 3000);
      }

      toastId++;

      return id;
    },
    [dismissById, setToasts]
  );

  const success = useCallback(
    ({ title = "Success", message, isInfinite }) =>
      createToast({ type: "success", title, message, isInfinite }),
    [createToast]
  );

  const error = useCallback(
    ({ title = "An Error Occured", message, isInfinite }) =>
      createToast({ type: "error", title, message, isInfinite }),
    [createToast]
  );

  const info = useCallback(
    ({ title = "Info", message, isInfinite }) =>
      createToast({ type: "info", title, message, isInfinite }),
    [createToast]
  );

  const basic = useCallback(
    ({ title = "Default", message, isInfinite }) =>
      createToast({ type: "default", title, message, isInfinite }),
    [createToast]
  );

  const clear = useCallback(() => {
    setToasts({});
  }, [setToasts]);

  const toaster = useMemo(() => {
    return {
      info,
      success,
      error,
      basic,
      clear,
    };
  }, [info, success, error, basic, clear]);

  return {
    toasts,
    dismissById,
    toaster,
    hasToasts: !!Object.keys(toasts).length,
  };
};

export default useToaster;
