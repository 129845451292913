import { useRef } from "react";

import { DateTime, Interval } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";

import Combobox from "core/components/Combobox";
import DatePicker from "core/components/DatePicker";
import { StyledInput } from "core/components/Form";
import BulkPasteInput from "core/components/BulkPasteInput";
import Utils from "common/utilities";

import { StyledIconContainer } from "./styles";
import { retrofitTextChangeHandler } from "./utilities";
// Field has to be imported like so, if not storybook throws Cannot access '__WEBPACK_DEFAULT_EXPORT__' before initialization.
import Field from "../Field";

export const CustomNumberInput = (props) => {
  const { onChange, value, min, max } = props;
  const ref = useRef();

  const focus = () => ref.current.focus();

  const handleDecrement = () => {
    const newValue = value - 1;
    if (newValue < min) {
      onChange(Number(min));
    } else {
      onChange(newValue);
    }
  };

  const handleIncrement = () => {
    const newValue = Number(value) + 1;
    if (newValue > max) {
      onChange(Number(max));
    } else {
      onChange(newValue);
    }
  };

  const handleChange = (event) => onChange(Number(event.target.value));

  return (
    <div
      style={{
        position: "relative",
      }}
      onClick={focus}
    >
      <StyledInput ref={ref} {...props} onChange={handleChange} />
      <div
        style={{
          position: "absolute",
          display: "flex",
          gap: "3px",
          height: "27px",
          top: "0px",
          right: "7px",
          alignItems: "center",
          color: "#706E6B",
        }}
      >
        <StyledIconContainer size="sm" onClick={handleDecrement}>
          <FontAwesomeIcon icon={faMinus} size="sm" />
        </StyledIconContainer>
        <StyledIconContainer size="sm" onClick={handleIncrement}>
          <FontAwesomeIcon icon={faPlus} size="sm" />
        </StyledIconContainer>
      </div>
    </div>
  );
};

const createDateTimeFromStringWithFallback = (dateString = "") => {
  const dateTimeInstance = dateString
    ? DateTime.fromFormat(dateString, "yyyy-MM-dd")
    : DateTime.invalid("dateString is not a valid DateTime.");

  return dateTimeInstance.isValid ? dateTimeInstance : null;
};

const createIntervalFromArrayWithFallback = (dateStringArray = ["", ""]) => {
  const [startDateString, endDateString] = dateStringArray;
  const intervalInstance =
    startDateString && endDateString
      ? Interval.fromDateTimes(
          createDateTimeFromStringWithFallback(startDateString),
          createDateTimeFromStringWithFallback(endDateString)
        )
      : Interval.invalid(
          "startDateString and/or endDateString are not valid DateTime(s)."
        );

  return intervalInstance.isValid ? intervalInstance : null;
};

const convertDateTimeToString = (dateTimeInstance) => {
  return dateTimeInstance?.toFormat("yyyy-MM-dd") || "";
};

const convertIntervalToArray = (intervalInstance) => {
  return [
    intervalInstance?.start.toFormat("yyyy-MM-dd") || "",
    intervalInstance?.end.toFormat("yyyy-MM-dd") || "",
  ];
};

export const TextEditorInput = (props) => {
  const {
    id,
    inputType,
    field,
    name,
    label,
    options,
    value,
    handleOnChange,
    isMultiSelect,
    isDateRange,
    dateType,
    isLoading,
  } = props;

  switch (inputType) {
    case "number":
      return (
        <Field
          id={field}
          title={label}
          type="number"
          variant="small"
          placeholder="Enter number"
          showSkeleton={isLoading}
          isMultiValue={isMultiSelect}
          value={value}
          onChange={handleOnChange}
        />
      );

    case "select":
      return (
        <div style={{ width: isMultiSelect ? "316px" : "158px" }}>
          <Combobox
            id={id}
            name={name}
            size="small"
            options={options}
            value={value}
            onChange={handleOnChange}
            placeholder={isMultiSelect ? "Select value(s)" : "Select value"}
            isSearchable={false}
            isMultiSelect={isMultiSelect}
            isLoading={isLoading}
            isDisabled={false}
            multiValueMaxDisplayCount={10}
          />
        </div>
      );

    case "zip":
      return isMultiSelect ? (
        <div style={{ width: isMultiSelect ? "316px" : "158px" }}>
          <BulkPasteInput
            id="zipCodes"
            label="Zip Codes"
            onChange={handleOnChange}
            size="small"
            validator={Utils.validateZip}
            value={value}
          />
        </div>
      ) : (
        <StyledInput
          id={field}
          name={name}
          title={label}
          size="small"
          placeholder="Enter text"
          isLoading={isLoading}
          value={value}
          onChange={retrofitTextChangeHandler(handleOnChange)}
          style={{
            height: "27px",
            fontSize: " 12px",
          }}
        />
      );

    case "date":
      // Matt: once we establish how the PL is bringing this data in we can abstract
      // this date value management to the utility functions
      const preparedValue = isDateRange
        ? createIntervalFromArrayWithFallback(value)
        : createDateTimeFromStringWithFallback(value);

      return (
        <DatePicker
          id={id}
          type={dateType}
          name={name}
          size="small"
          label={label}
          isRange={isDateRange}
          isLoading={isLoading}
          value={preparedValue}
          onChange={(newDateValue) => {
            // Don't update the value until an Interval instance has been returned
            // meaning both start and end dates have been selected
            if (isDateRange && newDateValue?.isLuxonInterval) {
              handleOnChange(convertIntervalToArray(newDateValue));
            } else {
              handleOnChange(convertDateTimeToString(newDateValue));
            }
          }}
        />
      );

    case "text":
    default:
      return (
        <Field
          id={field}
          title={label}
          type="text"
          variant="small"
          placeholder="Enter text"
          showSkeleton={isLoading}
          isMultiValue={isMultiSelect}
          value={value}
          onChange={handleOnChange}
        />
      );
  }
};
