import { z } from "zod";
import useForm from "core/hooks/useForm";
import Error from "core/components/Error";
import Field from "core/components/Field";
import Form from "core/components/Form/Form";
import { stateOptions } from "./OrgProductLicenseForm/constants";
import { validate } from "common/validation";

const formSchema = z.object({
  preferences: z.object({
    bi_portal: z.object({
      default_states: z.string(),
    }),
  }),
});

const OrgPreferencesForm = ({
  onSubmit,
  submitLabel = "Submit",
  defaultValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  error,
}) => {
  const { formValues, errors, onFormSubmit, onFieldChange, isTouched } =
    useForm({
      initialValues: defaultValues,
      disabledUntilTouched: true,
      onSubmit,
      formSchema,
      isFetchingInitialValues: isFetching,
    });

  return (
    <Form
      id="orgPreferences"
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      submitLabel={submitLabel}
      onCancel={onCancel}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      mode={"update"}
      isTouched={isTouched}
    >
      <Error>{error}</Error>
      <Field
        type="combobox"
        id="preferences.bi_portal.default_states"
        labelText="Default State"
        placeholder="None"
        showSkeleton={isFetching}
        isMultiValue={false}
        value={formValues?.preferences?.bi_portal?.default_states}
        error={errors.preferences?.bi_portal?.default_states}
        onChange={onFieldChange("preferences.bi_portal.default_states")}
        validator={validate()}
        options={stateOptions}
      />
    </Form>
  );
};

export default OrgPreferencesForm;
