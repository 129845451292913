import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";
import Form from "core/components/Form/Form";
import Error from "core/components/Error";
import useCurrentUser from "core/hooks/useCurrentUser";
import { minLength, required, email, validate } from "common/validation";
import {
  FLAG_KEYS,
  REVERSE_LOOKUP_ROLES,
  REVERSE_LOOKUP_TABLEAU_ROLES,
  ROLES,
  TABLEAU_ROLES,
} from "core/constants";
import { useCheckFeatureFlag } from "core/hooks/featureFlagging";
import useForm from "core/hooks/useForm";
import Field from "core/components/Field";
import BiPortalUserTooltip from "./BiPortalUserTooltip";

const formSchema = z.object({
  name: z.string().min(2),
  email: z.string().email(),
  alternateEmail: z.string().optional(),
  role: z.string().min(2),
  tableauConfig: z.object({
    tableauLicense: z.string(),
  }),
  direct_data_access_enabled: z.boolean(),
});

const UserForm = ({
  onSubmit,
  defaultValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  error,
}) => {
  const params = useParams();
  const { orgId: organizationId } = params;
  const { currentUser } = useCurrentUser();
  const hasDataAccessFeature = useCheckFeatureFlag(
    FLAG_KEYS.DATA_ACCESS_MANAGEMENT
  );

  const initialValues = useMemo(() => {
    return {
      name: defaultValues.name,
      email: defaultValues.email,
      alternateEmail: defaultValues.alternateEmail,
      role: defaultValues.role,
      tableauConfig: defaultValues.tableauConfig,
      direct_data_access_enabled: defaultValues.direct_data_access_enabled,
    };
  }, [defaultValues]);

  const {
    formValues,
    errors,
    isDisabled,
    isTouched,
    onFormSubmit,
    onFieldChange,
  } = useForm({
    placeholderValues: defaultValues,
    initialValues,
    disabledUntilTouched: true,
    onSubmit,
    formSchema,
    isFetchingInitialValues: isFetching,
  });

  return (
    <Form
      onSubmit={onFormSubmit}
      defaultValues={initialValues}
      submitLabel={`${defaultValues?.email ? "Update" : "Create"} User`}
      onCancel={onCancel}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      disabled={isDisabled}
      isTouched={isTouched}
      mode={defaultValues?.name ? "update" : "create"}
    >
      <Error>{error}</Error>

      <Field
        type="text"
        id="name"
        labelText="Name"
        showSkeleton={isFetching}
        autoComplete="off"
        isRequired={true}
        value={formValues.name}
        error={errors.name}
        onChange={(value, errorMessage) => {
          onFieldChange("name")(value, errorMessage);
          onFieldChange("email")(formValues.email);
        }}
        validator={validate(required(), minLength(2))}
      />
      <Field
        type="text"
        id="email"
        labelText="Email"
        showSkeleton={isFetching}
        autoComplete="off"
        isRequired={true}
        value={formValues.email}
        error={errors.email}
        onChange={onFieldChange("email")}
        validator={validate(required(), email())}
      />
      <Field
        type="text"
        id="alternateEmail"
        labelText="Alternate Email"
        showSkeleton={isFetching}
        autoComplete="off"
        value={formValues.alternateEmail}
        error={errors.alternateEmail}
        onChange={(value, errorMessage) => {
          onFieldChange("alternateEmail")(value, errorMessage);
          onFieldChange("email")(formValues.email);
        }}
        validator={validate(email())}
      />
      <Field
        type="combobox"
        id="role"
        labelText="Role"
        isRequired={true}
        value={formValues.role}
        error={errors.role}
        onChange={(value) => {
          onFieldChange("role")(value);
          onFieldChange("email")(formValues.email);
        }}
        validator={validate(required())}
        disabled={[ROLES.orgUser, ROLES.systemViewer].includes(
          currentUser.role
        )}
        options={[
          ...(organizationId === "populi" &&
          currentUser.role === ROLES.systemAdmin
            ? [
                {
                  value: REVERSE_LOOKUP_ROLES[ROLES.systemAdmin],
                  label: ROLES.systemAdmin,
                },
                {
                  value: REVERSE_LOOKUP_ROLES[ROLES.systemViewer],
                  label: ROLES.systemViewer,
                },
              ]
            : []),
          ...(organizationId === "populi" &&
          currentUser.role === ROLES.systemViewer
            ? [
                {
                  value: REVERSE_LOOKUP_ROLES[ROLES.systemViewer],
                  label: ROLES.systemViewer,
                },
              ]
            : []),
          {
            value: REVERSE_LOOKUP_ROLES[ROLES.orgAdmin],
            label: ROLES.orgAdmin,
          },
          {
            value: REVERSE_LOOKUP_ROLES[ROLES.orgUser],
            label: ROLES.orgUser,
          },
        ]}
      />
      <Field
        type="combobox"
        id="tableauConfig.tableauLicense"
        labelText=" BI Portal User"
        labelHint={<BiPortalUserTooltip />}
        isRequired={true}
        value={formValues?.tableauConfig?.tableauLicense}
        error={errors?.tableauConfig?.tableauLicense}
        onChange={(value) => {
          onFieldChange("tableauConfig.tableauLicense")(value);
          onFieldChange("email")(formValues.email);
        }}
        validator={validate(required())}
        disabled={[ROLES.orgUser].includes(currentUser.role)}
        options={[
          {
            value: REVERSE_LOOKUP_TABLEAU_ROLES[TABLEAU_ROLES.viewer],
            label: TABLEAU_ROLES.viewer,
          },
          {
            value: REVERSE_LOOKUP_TABLEAU_ROLES[TABLEAU_ROLES.explorer],
            label: TABLEAU_ROLES.explorer,
          },
          ...(organizationId === "populi"
            ? [
                {
                  value: REVERSE_LOOKUP_TABLEAU_ROLES[TABLEAU_ROLES.creator],
                  label: TABLEAU_ROLES.creator,
                },
              ]
            : []),
          {
            value: "",
            label: "None",
          },
        ]}
      />
      {hasDataAccessFeature && (
        <Field
          type="radio"
          id="direct_data_access_enabled"
          labelText="Enable Direct Data Access?"
          showSkeleton={isFetching}
          value={formValues.direct_data_access_enabled}
          onChange={(value) => {
            onFieldChange("direct_data_access_enabled")(value);
            onFieldChange("email")(formValues.email);
          }}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      )}
    </Form>
  );
};

export default UserForm;
