import { createRoot } from "react-dom/client";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PostHogProvider } from "posthog-js/react";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore, Log as OidcLog } from "oidc-client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyle from "./global-styles";
import UserProvider from "core/context/UserProvider";
import ToastProvider from "core/context/ToastProvider";
import FlashProvider from "core/context/FlashProvider";
import NavigationProvider from "core/context/NavigationProvider";
import ThemeProvider from "core/context/ThemeProvider";
import { Utils } from "common";

import "normalize.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import FeatureProvider from "core/context/FeatureProvider";
import { ENVIRONMENTS } from "common/utilities";

const currentEnvironment = Utils.getEnv();

const oidcSettings = {
  authority: process.env.REACT_APP_OIDC_AUTH_URL,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  response_type: "code",
  scope: process.env.REACT_APP_OIDC_SCOPE,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true, // default: true
  // https://authts.github.io/oidc-client-ts/interfaces/UserManagerSettings.html#revokeTokensOnSignout
  revokeTokensOnSignout: true, // default: false
  onSigninCallback: async (user) => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  monitorSession: true,
};

// Matt: Useful for monitoring internal user manager events
if (currentEnvironment !== ENVIRONMENTS.PROD) {
  OidcLog.logger = console;
}

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const queryCache = new QueryCache({});
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      refetchOnMount: false,
    },
  },
});

window.queryClient = queryClient;
window.queryCache = queryCache;

const OFFLINE_CACHE = `populi:${currentEnvironment}:offline`;

const localStoragePersister = createSyncStoragePersister({
  key: OFFLINE_CACHE,
  storage: window.localStorage,
});

const persistOptions = {
  queryClient,
  persister: localStoragePersister,
  maxAge: Infinity,
  dehydrateOptions: {
    shouldDehydrateMutation: () => false,
    shouldDehydrateQuery: (query) =>
      ["catalog", "settings"].includes(query.queryKey[0]),
  },
};

const PostHogProviderWrapper = ({ apiKey, options, children }) => {
  if (currentEnvironment !== ENVIRONMENTS.PROD) {
    return children;
  }

  return (
    <PostHogProvider apiKey={apiKey} options={options}>
      {children}
    </PostHogProvider>
  );
};

const root = createRoot(document.getElementById("root"));

root.render(
  <AuthProvider {...oidcSettings}>
    <PostHogProviderWrapper
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <ThemeProvider>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={persistOptions}
        >
          <FeatureProvider>
            <UserProvider>
              <FlashProvider>
                <ToastProvider>
                  <NavigationProvider>
                    <App />
                  </NavigationProvider>
                </ToastProvider>
              </FlashProvider>
            </UserProvider>
          </FeatureProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
        <GlobalStyle />
      </ThemeProvider>
    </PostHogProviderWrapper>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
