import { useCallback, useContext } from "react";
import FeatureContext from "../context/FeatureContext";

export const useFeatureFlaggingState = () => {
  const { flags, setFlags, context, setContext } = useContext(FeatureContext);

  const updateEntityContext = useCallback(
    (entityContext) => {
      const { entity, ...context } = entityContext;

      setContext((prevContext) => {
        return {
          ...prevContext,
          [entity]: context,
        };
      });
    },
    [setContext]
  );

  return {
    flags,
    context,
    setFlags,
    updateEntityContext,
  };
};

export const useCheckFeatureFlag = (flagKey) => {
  const { flags, context } = useContext(FeatureContext);
  console.debug("Checking feature availability.", { flagKey, flags, context });

  try {
    // VALIDATION
    // Return error for missing flagKey argument
    if (!flagKey) {
      throw Error("Error: useFeatureFlagging hook missing flagKey argument!");
    }

    // Return error for missing context argument
    if (!context) {
      throw Error("Error: useFeatureFlagging hook missing entity context!");
    }

    // Get the flag object from enum
    const matchingFlag = flags[flagKey];

    // Return error for undefined flag
    if (!matchingFlag) {
      throw Error("Error: No matching flag found!", { flagKey, flags });
    }

    // MAIN
    // Check the current context with the flag constraints
    // 1. Get matching entity context
    const matchingEntityContext = context[matchingFlag.entity];
    console.debug("Matching entity context.", matchingEntityContext);

    // 2. If no context for entity exists, assume no access allowed
    if (!matchingEntityContext) {
      return false;
    }

    // 3. Compare stage access of matching entity with flag stage
    const entityHasAccessToStage =
      matchingEntityContext.availableStages.includes(matchingFlag.stage);
    console.debug(
      "Entity has access to feature stage.",
      entityHasAccessToStage
    );

    return entityHasAccessToStage;
  } catch (error) {
    console.error(error);
    // DEFAULT
    return false;
  }
};
