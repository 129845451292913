import { API } from "common";
import { IMMUTABLE_LICENSES_PROPERTIES } from "core/constants";
import Utils from "common/utilities";

export const adminApiPermissions = [
  {
    api: "catalog",
    permission: "permission:catalog:read",
  },
  {
    api: "catalog-version",
    permission: "permission:catalog:read",
  },
];

export const getAdminApiPermission = (apiQueryKey) => {
  const adminApiPermission = adminApiPermissions.find(
    ({ api }) => api === apiQueryKey
  );
  return adminApiPermission?.permission;
};

export const getUsers = ({ organizationId, email, accessToken }) => {
  return API.get("/user?limit=0", {
    params: {
      organizationId,
      email,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getOrganizationById = ({ organizationId, accessToken }) => {
  return API.get(`/organization/${organizationId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getOrganizations = ({ organizationId, accessToken }) => {
  return API.get("/organization?limit=0", {
    params: {
      organizationId,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getOrganizationOnboarding = ({ organizationId, accessToken }) => {
  return API.get(`/organization/${organizationId}/onboarding`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getOrganizationDeployment = ({ organizationId, accessToken }) => {
  return API.get(`/organization/${organizationId}/deployment`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createOrganization = ({
  id,
  name,
  domain,
  primaryContact,
  accessToken,
  customer_segment,
  medicare_licensed,
}) => {
  return API.post(
    "/organization",
    {
      id,
      name,
      domain,
      primaryContact,
      customer_segment,
      medicare_licensed,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const createUser = ({
  organizationId,
  name,
  email,
  alternateEmail,
  password,
  role,
  tableauConfig,
  direct_data_access_enabled,
  accessToken,
}) => {
  const preparedTableauConfig = {
    ...tableauConfig,
    tableauUser: !!tableauConfig?.tableauLicense,
  };

  return API.post(
    `/user?organizationId=${organizationId}`,
    {
      name,
      email,
      alternateEmail,
      password,
      role,
      tableauConfig: preparedTableauConfig,
      direct_data_access_enabled,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const updateUser = ({
  name,
  email,
  alternateEmail,
  role,
  tableauConfig,
  organizationId,
  direct_data_access_enabled,
  accessToken,
}) => {
  const preparedTableauConfig = {
    ...tableauConfig,
    tableauUser: !!tableauConfig?.tableauLicense,
  };

  return API.put(
    `/user?organizationId=${organizationId}`,
    {
      name,
      email,
      alternateEmail,
      role,
      tableauConfig: preparedTableauConfig,
      direct_data_access_enabled,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const updateOrganization = ({
  accessToken,
  organizationId,
  ...requestBody
}) => {
  return API.put(
    `/organization?organizationId=${organizationId}`,
    {
      ...requestBody,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const setupOrganization = ({ organizationId, accessToken }) => {
  return API.post(
    `/organization/${organizationId}/onboarding`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const deployLicense = ({ organizationId, accessToken }) => {
  return API.post(
    `/organization/${organizationId}/deployment`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const updateOrganizationLicenses = ({
  organizationId,
  accessToken,
  licenses,
}) => {
  return API.put(
    `/licenses?organizationId=${organizationId}`,
    {
      licenses: Utils.objectWithFilteredKeys(
        licenses,
        IMMUTABLE_LICENSES_PROPERTIES
      ),
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const deactivateOrganization = (params) => {
  const { organizationId, accessToken } = params;
  return API.post(
    `/organization/${organizationId}/offboarding`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const activateOrganization = (params) => {
  const { organizationId, accessToken } = params;
  return API.post(
    `/organization/${organizationId}/activation`,
    {
      active: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const deactivateUser = (params) => {
  const { accessToken, email } = params;
  return API.put(
    "/user",
    { email, active: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const activateUser = (params) => {
  const { accessToken, email } = params;
  return API.put(
    "/user",
    { email, active: true },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getProductCatalog = ({
  accessToken,
  include,
  filters,
  isPrCatalog = false,
}) => {
  return API.get("/catalog/products", {
    params: {
      include: include.join(","),
      filters: JSON.stringify(filters),
      ...(isPrCatalog ? { pr_catalog: true } : {}),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getProductCatalogVersion = ({
  accessToken,
  isPrCatalog = false,
}) => {
  return API.get("/catalog/products/version", {
    params: {
      ...(isPrCatalog ? { pr_catalog: true } : {}),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const disableUserDataAccess = (params) => {
  const { accessToken, email } = params;
  return API.put(
    `/user/${email}/data-access`,
    { direct_data_access_enabled: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const enableUserDataAccess = (params) => {
  const { accessToken, email } = params;
  return API.put(
    `/user/${email}/data-access`,
    { direct_data_access_enabled: true },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const generateUserDataAccessCredential = (params) => {
  const { accessToken, email } = params;

  return API.post(
    `/user/${email}/credential`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const generateOrgDataAccessCredential = (params) => {
  const { accessToken, organizationId } = params;

  return API.post(
    `/organization/${organizationId}/credential`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const disableOrganizationCredentialById = (params) => {
  const { accessToken, organizationId, credentialId } = params;

  return API.put(
    `/organization/${organizationId}/credential/${credentialId}`,
    { credential_enabled: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const disableIndividualCredentialById = (params) => {
  const { accessToken, email, credentialId } = params;

  return API.put(
    `/user/${email}/credential/${credentialId}`,
    { credential_enabled: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getCustomerSegments = ({ accessToken, filters }) => {
  return API.get("/catalog/customer-segments", {
    params: {
      filters: JSON.stringify(filters),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
