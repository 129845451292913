import Skeleton from "react-loading-skeleton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ROLES, TABLEAU_ROLES } from "core/constants";
import Typography from "core/components/Typography";
import Pill from "core/components/Pill";
import Flex from "core/components/Flex";
import useToaster from "core/hooks/useToaster";
import useCurrentUser from "core/hooks/useCurrentUser";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useLocalStore from "core/hooks/useLocalStore";
import UserForm from "modules/admin/UserForm";
import { updateUser } from "modules/admin/actions";
import DetailsViewer from "../DetailsViewer";

export const UserGeneralInfoNavSection = ({
  profileUser,
  isLoading,
  isError,
  USER_STATUS_VARIANTS,
}) => {
  const localStore = useLocalStore();
  const params = useParams();
  const queryClient = useQueryClient();
  const { toaster } = useToaster();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { email, orgId: organizationId } = params;

  const queryKey = ["users", organizationId, { email }];
  const navigate = useNavigate();

  const apiCall = useAuthenticatedMutation((user) => {
    return updateUser({ ...user, organizationId });
  });

  const mutation = useMutation({
    mutationFn: apiCall,
    onSuccess: async (data, variables, context) => {
      // If a query successfully returns while this is working, the local storage will update,
      // but it will pull the old storage values and overwrite the "currentUser" object
      if (variables.email === currentUser.email) {
        localStore.setKey(localStore.KEYS.NAME, variables.name);
        localStore.setKey(localStore.KEYS.USER_ROLE, ROLES[variables.role]);
        setCurrentUser((previousUser) => {
          return {
            ...previousUser,
            ...variables,
          };
        });
      }

      // Update detail entry of user record in cache from updates in form
      queryClient.setQueryData(queryKey, (cachedUsers) => {
        const updatedUser = { ...cachedUsers[0], ...variables };

        // If we have already fetched the entire organizations user list
        // Lets just update this user's record in the cache
        const cachedUserList = queryClient.getQueryData([
          "users",
          organizationId,
        ]);

        if (cachedUserList) {
          queryClient.setQueryData(["users", organizationId], (cachedList) => {
            if (!cachedList) {
              return [];
            }

            return cachedList.map((cachedUser) => {
              if (cachedUser.email === updatedUser.email) {
                return {
                  ...cachedUser,
                  ...updatedUser,
                  isUpdated: true,
                };
              }

              return cachedUser;
            });
          });
        }

        return [updatedUser];
      });

      // Invalidate cached query results
      queryClient.invalidateQueries({ queryKey });

      // Close form
      navigate(`/admin/org-management/${organizationId}`);

      // Notify
      toaster.success({ message: "User has been updated." });
    },
  });

  return (
    <div>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom="5px"
      >
        <Typography variant="h2" noMargin>
          General
        </Typography>
        <div>
          {isLoading ? (
            <Skeleton style={{ width: "50px", height: "30px" }} />
          ) : (
            <Pill
              text={profileUser?.status}
              size="large"
              variant={USER_STATUS_VARIANTS[profileUser?.status]}
              tooltipStrategy="fixed"
              textDataCy="userProfileStatus"
            />
          )}
        </div>
      </Flex>
      {[ROLES.systemAdmin, ROLES.orgAdmin].includes(currentUser.role) ? (
        <UserForm
          onSubmit={mutation.mutate}
          defaultValues={profileUser}
          error={mutation.error}
          isFetching={isLoading || isError}
          isSubmitting={mutation.isPending}
        />
      ) : (
        <DetailsViewer
          isLoading={isLoading}
          data={[
            { label: "Name", value: profileUser?.name },
            { label: "Email", value: profileUser?.email },
            {
              label: "Alternate Email",
              value: profileUser?.alternateEmail || "None",
            },
            {
              label: "Role",
              value: profileUser?.roleLabel,
            },
            {
              label: "BI Portal User",
              value:
                TABLEAU_ROLES[profileUser?.tableauConfig.tableauLicense] ||
                "None",
            },
            {
              label: "Direct Data Access",
              value: profileUser?.direct_data_access_enabled
                ? "Enabled"
                : "Disabled",
            },
          ]}
        />
      )}
    </div>
  );
};
