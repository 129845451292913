import { useParams } from "react-router-dom";
import useForm from "core/hooks/useForm";
import { z } from "zod";
import { validate } from "common/validation";
import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import Field from "core/components/Field";

const formSchema = z.object({
  licenses: z.object({
    tableau_user_licenses: z.object({
      explorer_count: z.number(),
      viewer_count: z.number(),
      creator_count: z.number().optional(),
    }),
    user_limit: z.number(),
  }),
});

const OrganizationLicenceForm = ({
  onSubmit,
  submitLabel = "Submit",
  defaultValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  error,
  disabledUntilTouched = false,
}) => {
  const params = useParams();
  const { orgId: organizationId } = params;
  const { formValues, errors, isDisabled, onFormSubmit, onFieldChange } =
    useForm({
      initialValues: defaultValues,
      disabledUntilTouched,
      onSubmit,
      formSchema,
      isFetchingInitialValues: isFetching,
    });

  return (
    <Form
      id="userLicenses"
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      submitLabel={submitLabel}
      onCancel={onCancel}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      mode={!!defaultValues?.id ? "update" : "create"}
      disabled={isDisabled}
    >
      <Error>{error}</Error>
      <Field
        id="licenses.user_limit"
        type="number"
        min="0"
        labelText="User Limit"
        placeholder="Enter number"
        showSkeleton={isFetching}
        value={formValues?.licenses?.user_limit}
        onChange={onFieldChange("licenses.user_limit")}
        validator={validate()}
        error={errors.licenses?.user_limit}
      />
      <Field
        id="licenses.tableau_user_licenses.explorer_count"
        type="number"
        min="0"
        labelText="BI Portal Explorer"
        placeholder="Enter number"
        showSkeleton={isFetching}
        value={formValues?.licenses?.tableau_user_licenses?.explorer_count}
        onChange={onFieldChange(
          "licenses.tableau_user_licenses.explorer_count"
        )}
        validator={validate()}
        error={errors.licenses?.tableau_user_licenses?.explorer_count}
      />
      <Field
        id="licenses.tableau_user_licenses.viewer_count"
        type="number"
        min="0"
        labelText="BI Portal Viewer"
        placeholder="Enter number"
        showSkeleton={isFetching}
        value={formValues?.licenses?.tableau_user_licenses.viewer_count}
        onChange={onFieldChange("licenses.tableau_user_licenses.viewer_count")}
        validator={validate()}
        error={errors.licenses?.tableau_user_licenses?.viewer_count}
      />
      {organizationId === "populi" ? (
        <Field
          id="licenses.tableau_user_licenses.creator_count"
          type="number"
          min="0"
          labelText="BI Portal Creator"
          placeholder="Enter number"
          showSkeleton={isFetching}
          value={formValues?.licenses?.tableau_user_licenses?.creator_count}
          onChange={onFieldChange(
            "licenses.tableau_user_licenses.creator_count"
          )}
          validator={validate()}
          error={errors.licenses?.tableau_user_licenses?.creator_count}
        />
      ) : null}
    </Form>
  );
};

export default OrganizationLicenceForm;
